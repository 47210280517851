import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function groupbrandUseList() {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        // { key: 'id', label: 'ID', sortable: true },
        {key: 'brand_id', label: 'ID'},
        {key: 'name', label: '品牌全称'},
        // { key: 'erp_code', label: 'ERP编码', sortable: true },
        {key: 'short_name', label: '品牌昵称'},
        // { key: 'image', label: '图片', sortable: true },
        {key: 'state', label: '状态'},
        {key: 'is_white', label: '是否白牌'},
        {key: 'creator_id', label: '创建人'},
        {key: 'updater_id', label: '修改人'},
        {key: 'actions', label: '操作'},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('brand_id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        store
            .dispatch('groupbrand/search', {
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
            })
            .then(response => {
                const data = response.data.data
                const list = data.list
                listTotals.value = data.total

                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '品牌列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveBrandTypeCode = value => {
        const val = String(value)
        if (val === '1') return {label: '渠道客户'}
        if (val === '2') return {label: '集团公司'}
        if (val === '3') return {label: '供应商'}
        if (val === '4') return {label: '仓库方'}
        if (val === '5') return {label: '物流方'}
        return {}
    }

    const resolveStateCode = value => {
        const val = String(value)
        if (val === '1') return {label: '启用', color: 'success'}
        if (val === '0') return {label: '禁用', color: 'secondary'}
        return {}
    }

    const resolveIsWhiteCode = value => {
        const val = String(value)
        if (val === '0') return {label: '否', color: 'secondary'}
        if (val === '1') return {label: '是', color: 'success'}
        return {}
    }

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,

        resolveBrandTypeCode,
        resolveStateCode,
        resolveIsWhiteCode,
        refetchData,

        // Extra Filters
    }
}
